<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：新手向导界面
	开始时间：2022-10-17
	开发人员：刘巍骏
	最后修改：2022-10-17
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="guideBox">
    <!-- 触发方法按钮 -->
    <span id="routeBtn"></span>

    <!-- 新手向导界面 -->
    <iframe class="iframeBox" name="guidePage" :src = "guideurl"></iframe>
  </div>
</template>

<script>
  export default{
    name:'guideIndex',
    data(){
      return{
        guideurl:'',//新手向导界面数据
      }
    },
    created() {
      //初始化链接数据
      this.initUrl();
    },
    mounted() {
      /* 初始化dom操作 */
      this.initDomOperate();
    },
    methods:{
      /* 初始化界面数据 */
      initUrl(){
        this.guideurl = baseWebUrl + "/guide/xsxd.html";
      },

      /* 初始化dom操作 */
      initDomOperate(){
        let $this = this;
        //路由改变
        $("#routeBtn").click(function(){
          //获取需要跳转的路径名称
          let modelUrl = document.guidePage.modelUrl;
          //判断具体跳转的界面
          /* **************采购模块************** */
          $this.$router.push({
            path: modelUrl,
          })
        })
      },
    },
  }
</script>

<style lang="less">
  .guideBox{
    padding:0px;
    height: 100%;
    // border: 1px solid black;
    .iframeBox{
      width:100%;
      height:100%;
      border: none;
    }
  }
</style>
